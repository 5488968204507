import React, { useRef } from 'react';
/** lib, const **/
import classNames from 'classnames';
import { useInViewFadeIn, useInViewFadeUp } from 'lib/use-in-view';
import { SERVICE_NAME } from '../../const';
/** images **/
import TopHumanImg from 'assets/images/top-human.svg';
import { ReactComponent as TopItem01 } from 'assets/images/top-item01.svg';
import { ReactComponent as TopItem02 } from 'assets/images/top-item02.svg';
import { ReactComponent as TopItem03 } from 'assets/images/top-item03.svg';
import { ReactComponent as TopItem04 } from 'assets/images/top-item04.svg';
import { ReactComponent as TopItem05 } from 'assets/images/top-item05.svg';
import { ReactComponent as TopItem06 } from 'assets/images/top-item06.svg';


/** styles **/
import s from '../../styles/organisms/key-visual.module.scss';

export const KeyVisual: React.FC = () => {
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  const inViewRef2 = useRef<HTMLHeadingElement>(null);
  const inViewRef3 = useRef<HTMLHeadingElement>(null);
  const inViewRef4 = useRef<HTMLHeadingElement>(null);
  const inViewRef5 = useRef<HTMLHeadingElement>(null);
  const inViewRef6 = useRef<HTMLHeadingElement>(null);
  const inViewRef7 = useRef<HTMLHeadingElement>(null);
  const inViewRef8 = useRef<HTMLHeadingElement>(null);
  const inViewRef9 = useRef<HTMLHeadingElement>(null);
  const inViewRef10 = useRef<HTMLHeadingElement>(null);
  const inViewRef11 = useRef<HTMLHeadingElement>(null);

  useInViewFadeUp([inViewRef1, inViewRef2, inViewRef3, inViewRef4], {
    rootMargin: '-15% 0px -16% 0px',
  });
  useInViewFadeIn([inViewRef5, inViewRef6, inViewRef7, inViewRef8, inViewRef9, inViewRef10, inViewRef11], {
    rootMargin: '0% 0px -16% 0px',
  });


  return (
    <div className={s.keyVisualWrapper}>
      <div className={classNames(s.text, s.spOnly)}>
        <h2>
          ペットのコンシェルジュがいる
          <br />
          マンション
        </h2>
        <p translate="no">
          おもいっきり、ペットを愛でよう。暮らしを楽しもう。
          <br />
          ペットと仲間と共に
        </p>
      </div>
      <div className={s.imagesWrapper}>
        <div className={s.beforeFadeUp} ref={inViewRef11}>
          <img className={s.topHuman} src={TopHumanImg} alt={SERVICE_NAME} width={381} height={500} />
        </div>
        <div className={s.topItemswrapper}>
          <div className={classNames(s.scrollList, s.pcOnly)}>
            <div className={classNames(s.topItem, s.topItem01, s.beforeFadeIn, s.delay02)} ref={inViewRef5}>
              <TopItem01 className={s.image} />
              <p>いろんなシェア</p>
            </div>
            <div className={classNames(s.topItem, s.topItem02, s.beforeFadeIn, s.delay01)} ref={inViewRef6}>
              <TopItem02 className={s.image} />
              <p>寄り添い</p>
            </div>
            <div className={classNames(s.topItem, s.topItem03, s.beforeFadeIn, s.delay03)} ref={inViewRef7}>
              <TopItem03 className={s.image} />
              <p>見守り</p>
            </div>
            <div className={classNames(s.topItem, s.topItem04, s.beforeFadeIn, s.delay04)} ref={inViewRef8}>
              <TopItem04 className={s.image} />
              <p>訪問ペットシッター</p>
            </div>
            <div className={classNames(s.topItem, s.topItem05, s.beforeFadeIn, s.delay05)} ref={inViewRef9}>
              <TopItem05 className={s.image} />
              <p>犬の保育園</p>
            </div>
            <div className={classNames(s.topItem, s.topItem06, s.beforeFadeIn, s.delay06)} ref={inViewRef10}>
              <TopItem06 className={s.image} />
              <p>共有ラウンジ</p>
            </div>
          </div>
          <div className={classNames(s.scrollList, s.spOnly)}>
            <div className={classNames(s.topItem, s.topItem01)}>
              <TopItem01 className={s.image} />
              <p>いろんなシェア</p>
            </div>
            <div className={classNames(s.topItem, s.topItem02)}>
              <TopItem02 className={s.image} />
              <p>寄り添い</p>
            </div>
            <div className={classNames(s.topItem, s.topItem03)}>
              <TopItem03 className={s.image} />
              <p>見守り</p>
            </div>
            <div className={classNames(s.topItem, s.topItem04)}>
              <TopItem04 className={s.image} />
              <p>訪問ペットシッター</p>
            </div>
            <div className={classNames(s.topItem, s.topItem05)}>
              <TopItem05 className={s.image} />
              <p>犬の保育園</p>
            </div>
            <div className={classNames(s.topItem, s.topItem06)}>
              <TopItem06 className={s.image} />
              <p>共有ラウンジ</p>
            </div>
          </div>
          <div className={classNames(s.scrollList, s.spOnly)}>
            <div className={classNames(s.topItem, s.topItem01)}>
              <TopItem01 className={s.image} />
              <p>いろんなシェア</p>
            </div>
            <div className={classNames(s.topItem, s.topItem02)}>
              <TopItem02 className={s.image} />
              <p>寄り添い</p>
            </div>
            <div className={classNames(s.topItem, s.topItem03)}>
              <TopItem03 className={s.image} />
              <p>見守り</p>
            </div>
            <div className={classNames(s.topItem, s.topItem04)}>
              <TopItem04 className={s.image} />
              <p>訪問ペットシッター</p>
            </div>
            <div className={classNames(s.topItem, s.topItem05)}>
              <TopItem05 className={s.image} />
              <p>犬の保育園</p>
            </div>
            <div className={classNames(s.topItem, s.topItem06)}>
              <TopItem06 className={s.image} />
              <p>共有ラウンジ</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(s.pcOnly, s.textWrapper)}>
        <div className={classNames(s.pcOnly, s.text)}>
          <h2 className={classNames(s.beforeFadeUp)} ref={inViewRef1}>
            ペットのコンシェルジュがいる賃貸マンション P-man
          </h2>

          <p>
            <span className={classNames(s.beforeFadeUp)} ref={inViewRef2}>
              おもいっきり、ペットを愛でよう。暮らしを楽しもう。
            </span>
            <br />
            <span className={classNames(s.beforeFadeUp)} ref={inViewRef4}>
              ペットと仲間と共に
            </span>
          </p>
        </div>

      </div>
    </div>
  );
};
