import React, { useState } from 'react';
import { ComicsList } from 'lib/comics';
/** styles **/
import s from '../../styles/organisms/comics.module.scss';

/** components **/
import { ContentsTitle } from '../molecules';
import classNames from 'classnames';

const TYPES = ['急な出張時でも', 'シニアペットも安心', '運動不足解消', '仲間との情報シェア'];
export const Comics: React.FC = () => {
  const [selectedType, setSelectedType] = useState<string>('急な出張時でも');

  return (
    <div className={s.comicsWrapper}>
      <ContentsTitle className={s.contentsTitle} title={`P-man４コマ劇場`} subTitle={`4コマ漫画でわかるP-manライフ`} />
      <div className={s.tab}>
        <div className={s.inner}>
          {TYPES.map((type, index) => (
            <p className={selectedType === type ? s.selected : ''} key={index} onClick={() => setSelectedType(type)}>
              {type}
            </p>
          ))}
        </div>
      </div>
      <div className={s.comicsContents}>
        {ComicsList.map((comic, index) => (
          <div key={index}>
            {comic.type === selectedType && (
              <div className={s.comicListWrapper}>
                <p className={classNames(s.label, s.nomal)}>
                  P-manでは
                  <br className={s.pcOnly} />
                  ない生活
                </p>
                {comic.nomalImages.map((image, imageindex) => (
                  <div className={s.imageWrapper} key={imageindex}>
                    <span>{imageindex + 1}</span>
                    <img key={index} src={require(`assets/images/${image}`)} alt={comic.type} />
                  </div>
                ))}
              </div>
            )}
            {comic.type === selectedType && (
              <div className={s.comicListWrapper}>
                <p className={s.label}>P-manの生活</p>
                {comic.images.map((image, imageindex) => (
                  <div className={s.imageWrapper} key={imageindex}>
                    <span>{imageindex + 1}</span>
                    <img key={index} src={require(`assets/images/${image}`)} alt={comic.type} />
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
