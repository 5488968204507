import React, { useEffect } from 'react';
/** components **/
import { FadeUp, Layout } from 'components/organisms';
import { ContentsTitle } from 'components/molecules';
/** styles **/
import s from '../styles/pages/terms.module.scss';

export const Terms: React.FC = () => {
  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'page_view', {
        page_path: '/terms',
      });
    }
  }, []);
  return (
    <Layout>
      <div className={s.termsPageWrapper}>
        <FadeUp delay={0}>
          <ContentsTitle className={s.contentsTitle} title="TERMS OF SERVICE" subTitle="利用規約" />
        </FadeUp>
        <FadeUp delay={0}>
          <div className={s.inner}>
            <p className={s.intro}>
              本利用規約（以下「本規約」といいます。）には、本サイト（第 2 条に定義）への登録及 び本企画（第 2
              条に定義）への参加における三菱地所株式会社（以下「当社」といいます。） と登録者（第 2
              条に定義）の皆様との間の権利義務関係が定められています。本サイトへ登
              録又は本企画にご参加頂ける方は、本規約に同意する前に、必ず全文をお読み下さいますよ うお願い致します。
            </p>
            <h5>第1条（適用）</h5>
            <div className={s.block}>
              <p>
                1.本規約は、登録者が本サイトへ登録及び本企画へ参加するにあたって生じる当社と登録者との間の権利義務関係を定めることを目的とし、登録者と当社との間の本サイト登
                録及び本企画への参加に関わる一切の関係に適用されます。
              </p>
              <p>2.当社が本サイト上で掲載する本サイト登録に関するルールは、本規約の一部を構成する ものとします。</p>
            </div>
            <h5>第2条（定義）</h5>
            <div className={s.block}>
              本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
              <p>
                1.「本企画」とは、当社がペットライフサポートのマンション企画検証のために実施する本サイトの運営、及び本SNS（本条第4項に規定）に関する一連の企画を意味します。
              </p>
              <p>
                2.「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
              </p>
              <p>
                3.「登録希望者」とは、本サイトに登録をしようとする個人又は法人のことを意味し、本サイトの閲覧者も含みます。
              </p>
              <p>4.「登録情報」とは、第3条において定義された「登録情報」を意味します。</p>
              <p>5.「登録者」とは、第3条に基づいて本サイトに登録した個人又は法人を意味します。</p>
              <p>
                6.「本サイト」とは、当社がインターネット上で提供する「P-man 三菱地所のペット
                ライフサポート」の以下のWEBサイト（理由の如何を問わず当社のウェブサイトのURL又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
                <br />
                URL{' '}
                <a href="https://www.p-man.mec.co.jp/" target="_blank" rel="noreferrer">
                  https://www.p-man.mec.co.jp/
                </a>
              </p>
              <p>
                7.「本SNS」とは、当社が本企画のために開設するSNSを意味します。
                <br />
                Instagram
                <a href="https://www.instagram.com/pman.mec" target="_blank" rel="noreferrer">
                  https://www.instagram.com/pman.mec
                </a>
              </p>
              <p>
                8.「提供データ」とは、登録者が本サイトへの登録又は本企画への参加を通じて当社に提供する又は投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。
              </p>
            </div>
            <h5>第3条（登録）</h5>
            <div className={s.block}>
              <p>
                1.登録希望者は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、本サイトに登録することができます。
              </p>
              <p>
                2.前項に定める本サイトへの登録は必ず本サイトを利用又は本企画に参加する個人又は法人自身が行わなければならず、原則として代理人による登録は認められません。また、登録希望者は、本サイトに登録するにあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
              </p>
              <p>
                3.登録者は、当社が前項に基づき取得した登録情報を基に、登録者に対して本企画に関する情報提供依頼やヒアリング依頼を行うことを予め了承するものとします。
              </p>
              <p>4.登録者は以下各号の条件を満たすことを予め保証するものとします。</p>
              <ul>
                <li>(ア)満 18 歳以上であること。</li>
                <li>(イ)当社と連絡が取れる手段（電子メールアドレス及び電話番号）を保有していること。</li>
                <li>(ウ)本規約のすべての条項に同意すること。</li>
              </ul>
              <p>
                5.当社は、登録者が、以下の各号のいずれかの事由に該当する場合は本サイト利用及び本企画への参加を拒否することがあります。尚、当該理由について、当社は登録者に
                対して一切の開示義務を負いません。
              </p>
              <ul>
                <li>(1) 本規約に違反するおそれがあると当社が判断した場合</li>
                <li>(2) 当社に提供した登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合 </li>
                <li>(3) 第10条に定める措置を受けたことがある場合</li>
                <li>
                  (4)未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
                </li>
                <li>
                  (5)反社会的活動を行う団体、若しくはこれらと関連のある団体、その他反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団員又は暴力団準構成員でなくなった日から5年を経過しない者、暴力団関係企業、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団その他暴力、威力又は詐欺的手法を使用して経済的利益を追求する集団又は個人を意味します。以下同じ。）に所属している者、又はその団体又は資金提供その他を通じて反社会的勢力の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力との何らかの交流若しくは関与を行っている場合。
                </li>
                <li>(6)登録希望者が過去当社との契約に違反した者又はその関係者である場合</li>
                <li>(7) その他、登録希望者が本サイトに登録することが適当でないと当社が判断した場合</li>
              </ul>
            </div>
            <h5>第4条 （本企画の目的等）</h5>
            <div className={s.block}>
              本企画は当社がペットマンションに関する事業検証・ユーザーニーズ検証を目的に実施するものであり、マンションその他住宅に資するサービス等を提供するものでありません。また、本サイト及び本企画において謳うマンションその他住宅に付随するサービスは全て検討段階にあり、当社がサービス提供を確約するものではありません。
            </div>
            <h5>第5条 （本サイトの停止等及び本企画の中断・終了）</h5>
            <div className={s.block}>
              <p>
                1．当社は、以下の各号の場合には、登録者に事前に通知することなく、本サイトの全部
                又は一部の利用を永久的に停止、又は一時的に中断する場合があります。
              </p>
              <ul>
                <li>
                  （ア）本サイトにかかわるコンピューター・システムの点検、又は保守作業を定期 的に又は緊急に行う場合。
                </li>
                <li>（イ）コンピューター、通信回線等が事故により停止した場合。</li>
                <li>（ウ）火災、停電、天災地変などの不可抗力により本サイトの運営ができなくなっ た場合。</li>
                <li>（エ）その他、当社が本サイトを実施・運営することが困難と判断した場合。</li>
              </ul>
              <p>2．当社は、登録者に対して事前に通知することなく、本企画を終了することができるものとします。</p>
              <p>
                3．当社は、本条に基づき当社が行った措置に基づき登録者に生じた不利益又は損害について、当社は当社の故意又は重過失による場合を除き、一切責任を負わないものとします。
              </p>
            </div>
            <h5>第6条（有効期間）</h5>
            <div className={s.block}>
              <p>
                本利用規約は、登録者について第3条に基づく登録が完了した日に効力を生じ、当該登録者の登録が取り消された日又は本サイト又は本企画が終了した日のいずれか早い日まで、当社と登録者との間で有効に存続するものとします。
              </p>
            </div>
            <h5>第7条（本規約等の変更）</h5>
            <div className={s.block}>
              <p>1．当社は、本企画の内容を自由に変更できるものとします。</p>
              <p>
                2．当社は、本規約（本サイト等に掲載する本企画に関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更する場合には、変更の内容及び変更の効力発生時期を、当該効力発生時期までに当社所定の方法で告知するものとします。告知された効力発生時期以降に登録者が本サイトを利用又は本企画に参加した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、登録者は、本規約の変更に同意したものとみなします。
              </p>
            </div>
            <h5>第8条（登録者の禁止事項）</h5>
            <div className={s.block}>
              登録者は、本サイトへの登録及び本企画参加にあたり、故意・過失を問わず以下の各号に該当する行為（以下「禁止行為」といいます。）又は該当すると当社が判断する行為をしてはなりません。
              <ul>
                <li>(ア) 虚偽の情報を提供する行為。 </li>
                <li>
                  (イ)
                  当社、他の登録者、又は第三者の著作権、肖像権、その他知的所有権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
                </li>
                <li>(ウ) 当社、他の登録者、又は第三者を誹謗中傷する行為。</li>
                <li>
                  (エ) 当社、他の登録者又はその他の第三者に対する暴力行為、威嚇行為、迷惑行為そ の他の不適切な行為。
                </li>
                <li>(オ) その他法令等に違反する行為、又はそのおそれのある行為。</li>
                <li>(カ) 犯罪的行為に結びつく行為。</li>
                <li>(キ) 公序良俗に反する行為。</li>
                <li>(ク) 反社会的活動に関する行為。</li>
                <li>(ケ) 当社、他の登録者、又は第三者に不利益、損害、不快感を与える行為</li>
                <li>(コ) 他サービス及びサイトへの勧誘又は募集行為。</li>
                <li>(サ) 本企画を通じて入手した情報を、複製、販売、出版その他私的利用の範囲を超えて使用する行為。</li>
                <li>(シ) 本企画の運営を妨げ、あるいは当社の信用を毀損するような行為、又はそのおそれのある行為。</li>
                <li>(ス) 法令、当社若しくは登録者が所属する業界団体の内部規則に違反する行為</li>
                <li>(セ) コンピュータ・ウィルスその他の有害なコンピュータ・プログラムを含む情報を送信する行為。</li>
                <li>(ソ) 当社が定める一定のデータ容量以上のデータを本サイトを通じて送信する行為</li>
                <li>(タ) 同一の登録ユーザーが重複して登録の申請を行う行為</li>
                <li>(チ) 本企画に関し利用しうる情報を改ざんする行為。</li>
                <li>(ツ) 本規約に違反する行為。</li>
                <li>(テ) その他、当社又が不適切と合理的に判断する行為。</li>
              </ul>
              <p>
                2．当社は、本企画において、登録者が前項その他本規約の規定に違反し又は前項各号に定めるいずれかの行為を行い若しくは行うおそれがあると当社が合理的に判断した場合には、登録者に事前に通知することなく、本サイトの利用停止その他の措置をとることができるものとします。当社は、本項に基づき当社が行った措置に基づき登録者に生じた損害について一切の責任を負いません。
              </p>
            </div>
            <h5>第9条 （ペットの写真等の投稿）</h5>
            <div className={s.block}>
              登録者は、登録者が当社所定の条件に従い当社に提供した、本企画に関するコメント及び
              登録者が飼育するペットの写真又は動画等の投稿データを、当社が本サイト又は本 SNS
              に掲載・投稿することを予め了承するものとします。
            </div>
            <h5>第10条 （登録取消等）</h5>
            <div className={s.block}>
              <p>
                1．当社は、登録者が、以下の各号のいずれかの事由に該当する場合は、事前に通知又は
                催告することなく、当該登録者について本サイトの利用及び本企画への参加を一時的に停止し、又は登録者としての登録を抹消することができます。
              </p>
              <ul>
                <li>(1)本規約のいずれかの条項に違反した場合</li>
                <li>(2)登録事項に虚偽の事実があることが判明した場合</li>
                <li>
                  (3)当社、他の登録者、又は第三者に損害を生じさせるおそれのある目的又は方法で本サイト及び本企画を利用した、又は利用しようとした場合
                </li>
                <li>(4)第3条第5項各号に該当する場合</li>
                <li>(5)その他、当社が登録者としての登録の継続を適当でないと合理的に判断した場合</li>
              </ul>
              <p>
                2．前項各号のいずれかの事由に該当した場合、登録者は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を
                行わなければなりません。
              </p>
              <p>
                3．当社及び登録者は、当社所定の方法で相手方に通知することにより、登録者の登録を取り消すことができます。
              </p>
              <p>4．当社は、本条に基づき当社が行った行為により登録者に生じた損害について一切の責任を負いません。</p>
            </div>
            <h5>第11条 保証の否認及び免責</h5>
            <div className={s.block}>
              <p>
                1．当社は、当社が本企画で構想するマンションその他住宅に付随
                するサービスを登録者に提供するものではありません。
              </p>
              <p>
                2．登録者による本サイト利用及び本企画参加に関連して、登録者が損害を被った場合、当社の故意又は重過失による場合を除き、当は責任を負わないものとします。
              </p>
              <p>
                3．登録者が当社から直接又は間接に、本企画、本サイトの他の登録者その他の事項に関する何らかの情報を得た場合であっても、当社は登録者に対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
              </p>
              <p>
                4．本企画は、外部サービスと連携することがありますが、かかる連携を保証するものではなく、外部サービスとの連携の支障等について、当社の責に帰すべき場合を除き、当社は一切の責任を負いません。
              </p>
              <p>
                5．登録者は、本サイト及び本企画を利用することが、登録者に適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、登録者による本サイト及び本企画の利用が、登録者に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
              </p>
              <p>
                6．本サイト又は本企画に関連して登録者と他の登録者、その他の第三者との間において生じた取引、連絡、紛争等については、登録者の責任において処理及び解決するものとし、当社の責に帰すべき場合を除き、当社はかかる事項について一切責任を負い
                ません。
              </p>
              <p>
                7．当社は、当社による本サイト及び本企画の提供の中断、停止、終了、利用不能又は変更、登録者のメッセージ又は情報の削除又は消失､登録者の登録の取消、本サイトの利用によるデータの消失又は機器の故障若しくは損傷、その他本サイト及び本企画に関連して登録者が被った損害につき、当社の責に帰すべき場合を除き、賠償する責任を一切負わないものとします。
              </p>
              <p>
                8．本サイト又は本 SNS から他のウェブサイトへのリンク又は他のウェブサイトから本
                サイト又は本SNSへのリンクが提供されている場合でも、当社は、本サイト及び本SNS以外のウェブサイト及びそこから得られる情報に関して、当社の責に帰すべき場合を除き、一切の責任を負わないものとします。
              </p>
              <p>
                9．当社は、当社の合理的な支配の及ばない状況（火事、停電、ハッキング、コンピューター・ウィルスの侵入、地震、洪水、戦争、疫病、通商停止、ストライキ、暴動、物資及び輸送施設の確保不能、政府当局若しくは地方自治体による介入、指示若しくは要請、又は内外法令の制定若しくは改廃を含みますがこれらに限定されません。）により利用契約上の義務を履行できない場合、その状態が継続する期間中登録者に対し債務不履行責任を負わないものとします。
              </p>
            </div>
            <h5>第12条（登録者の賠償等の責任</h5>
            <div className={s.block}>
              <p>
                1．登録者は、本規約に違反することにより、又は本サイト及び本企画参加に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
              </p>
              <p>
                2．登録者が、本企画に関連して他の登録者、その他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにそ
                の内容を当社に通知するとともに、登録者の費用と責任において当該クレーム又は紛争を処
                理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
              </p>
              <p>
                3.
                登録者による本サイト及び本企画の利用に関連して、当社が、他の登録者、その他の第三者から権利侵害その他の理由により何らかの請求を受けた
                場合は、登録者は当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
              </p>
            </div>
            <h5>第13条 知的財産権</h5>
            <div className={s.block}>
              <p>
                1．当社は、登録者の登録情報、提供データについて、個人を特定できない形式に適宜、編集の上、無償かつ無期限で利用できるものとします。尚、この場合の二次的著作物の著作権は当社に帰属します。
              </p>
              <p>
                2．本サイト及び本企画に関する一切の著作権、その他の知的財産権は、当社又は当社が指定する第三者に帰属します。
              </p>
            </div>
            <h5>第14条 個人情報の取扱</h5>
            <div className={s.block}>
              <p>
                1．当社は、本登録情報及び本企画の実施に際し、登録者の個人情報（個人情報の保護に関する法律で定義される「個人情報」を意味します。以下「個人情報」といいます。）を個人情報の保護に関する法律、その他の関係法令及び当社のプライバシーポリシーに従って、適切に管理します。
              </p>
              <p>
                2．当社による登録者の個人情報の取扱いについては、別途定める当社のプライバシーポリシーの定めによるものとし、登録者はこのプライバシーポリシーに従って当社が
                登録者の個人情報を取扱うことについて同意するものとします。
              </p>
              <p>
                3．当社は、登録者が当社に提供した情報（登録事項を含む。）、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で使用及び公開することができるものとし、登録者はこれに異議を唱えないものとします。
              </p>
            </div>
            <h5>第15条 秘密保持</h5>
            <div className={s.block}>
              <p>
                1．本規約において「秘密情報」とは、本規約又は本サイト及び本企画に関連して、登録者が、当社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関するすべての情報
                を意味します。但し、以下記載のものについては、秘密情報から除外するものとします。
              </p>
              <ul>
                <li>
                  (ア)
                  当社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの
                </li>
                <li>
                  (イ) 当社から提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊
                  行物その他により公知となったもの
                </li>
                <li>(ウ) 提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に 取得したもの</li>
                <li>(エ) 秘密情報によることなく単独で開発したもの</li>
                <li>(オ) 当社から秘密保持の必要なき旨書面で確認されたもの</li>
              </ul>
              <p>
                2．登録者は、秘密情報を本サイト及び本企画の利用の目的のみに利用するとともに、当社の書面による承諾なしに第三者に秘密情報を提供、開示又は漏洩しないものとします。
              </p>
              <p>
                3．前項の定めに拘わらず、登録者は、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を当社に通知しなければなりません。
              </p>
              <p>
                4．登録者は、秘密情報を記載した文書又は磁気記録媒体等を複製する場合には、事前に当社の書面による承諾を得ることとし、複製物の管理については第2項に準じて厳重に行うものとします。
              </p>
              <p>
                5．登録者は、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びそのすべての複製物を返却又は廃棄しなければなりません。
              </p>
            </div>
            <h5>第16 条（推奨環境）</h5>
            <div className={s.block}>
              本サイトでは、より安全で快適にご利用いただくために下記のプラウザ、あるいはプラ
              グインなどが必要です。ご利用のソフトウェアのバージョンをご確認の上、ダウンロー ドしてください。
              <div className={s.recommendDevice}>
                推奨 OSおよびブラウザ
                <p>本サイトを安全で快適にご利用いただくために、下記バージョンのブラウザのご利用をお勧めいたします。</p>
                <p className={s.os}>
                  <span>Windows をお使いの場合</span>
                  <br />
                  ・Internet Explorer 6.0 以上
                  <br />
                  ・Firefox 2.0 以上
                </p>
                <p className={s.os}>
                  <span>Macintosh をお使いの場合</span>
                  <br />
                  ・Safari 1.3 以上
                  <br />
                  ・Firefox 2.0 以上
                </p>
              </div>
            </div>
            <h5>第17条 連絡/通知</h5>
            <div className={s.block}>
              本サイト及び本企画に関する問い合わせその他登録者から当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から登録者に対する連絡又は通知は、当社の定める方法で行うものとします。
            </div>
            <h5>第18条 情報セキュリティ</h5>
            <div className={s.block}>
              本サイトでは、本サイトへのアクセス状況を把握するために、Google
              社の提供するサービスであるGoogleアナリティクス（情報の送信先は Google LLC
              及びその関係会社となります。）を利用し、インターネット通信に通常用いられるシステム、デバイス、ネットワーク、通信に関する情報、位置情報、サイト・アプリ上での行動に関するデータ閲覧ページに関するデータユーザー識別子（cookie、端末識別子等）の情報を送信する場合があります。登録者は、以下各号に記載の内容を理解した上、登録者のかかる情報が送信されることについて、同意するものとします。
              <p>
                (ア)Google アナリティクスはアクセス情報の収集のために Cookie
                を使用しています。このアクセス情報は匿名で収集されており、個人を特定するものではあり ません。詳細は
                Googleアナリティクスの利用規約及びプライバシーポリシー
                をご覧ください。尚、Googleアナリティクスによる情報収集を無効化したい場 合は、Google 社が提供する「Google
                アナリティクスオプトアウト アドオン」 からオプトアウトの設定をすることで実施可能です。
              </p>
              <p>
                (イ)登録者のブラウザからのアクセス状況を知るためにクッキー、Web ビーコン及
                びリファラーを使用することがあります。当社はアクセス状況を分析して、申
                し込みサイト改善に役立てるために利用することがあります。尚、リファラー
                とは、アクセスログに記載されている情報のひとつで、当該ファイルを取得す
                る（ブラウザで表示する。）直前に閲覧していたページの URL を内容とする情 報です。又、Web
                ビーコンとは、クリア GIF と称する 1×1 ピクセルの目に
                見えない透明なファイルをページに貼り付けることで、Web ビーコンが置かれ
                たページへの、お客さまの閲覧情報を収集する仕組みです。
              </p>
            </div>
            <h5>第19条 分離可能性</h5>
            <div className={s.block}>
              本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び登録者は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
            </div>
            <h5>第20条 準拠法及び合意管轄</h5>
            <div className={s.block}>
              <p>
                1．本規約は日本法に基づき解釈されるものとし、本規約の一部の規定が無効な場合でも、適用可能な他の規定ついては効力があるものとします。
              </p>
              <p>
                2．本サービスに関連して訴訟、調停の必要が生じた場合には、東京地方裁判所を専属的合意管轄裁判所とします。
              </p>
            </div>
            <h5>第21条 協議解決</h5>
            <div className={s.block}>
              当社、及び登録者は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
            </div>
            <p className={s.finish}>以上</p>
          </div>
        </FadeUp>
      </div>
    </Layout>
  );
};
