import React, { useEffect, useRef } from 'react';
/** lib **/
import { useScrollToAnchor } from 'lib/use-scroll-anker-link';
import { Link } from 'react-router-dom';
/** components **/
import {
  KeyVisual,
  News,
  WhatIsPman,
  IssuesInPetOwnership,
  PossibleItems,
  OurFutures,
  Friends,
  Comics,
  Worries,
  Equipment,
  AreaFloorPlanRent,
  Concept,
  Member,
  OurPartner,
  SupporterVoice,
  Faq,
  Layout,
} from 'components/organisms';
/** styles **/
import s from '../styles/pages/top.module.scss';

import Pwan from 'assets/images/pwan.png';

export const Top: React.FC = () => {
  const { scrollToAnchor } = useScrollToAnchor({ smooth: true });
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const animationEl = [inViewRef1];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(s.inview);
          } else {
            entry.target.classList.remove(s.inview);
          }
        });
      },
      {
        root: null,
        rootMargin: '-90% 0px 20% 0px',
        threshold: 0,
      },
    );
    animationEl.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      animationEl.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [animationEl]);

  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'page_view', {
        page_path: '/',
      });
    }
  }, []);

  useEffect(() => {
    scrollToAnchor();
  }, [scrollToAnchor]);

  return (
    <Layout>
      <div id="top" className={s.topWrapper}>
        <div className={s.kv} ref={inViewRef1}>
          <KeyVisual />
          <div className={s.notice}>
            <Link to="/#news">
              <p>P-manからのお知らせがあるよ！</p>
              <img src={Pwan} alt="ピーワン" />
            </Link>
          </div>
        </div>
        <div id="news">
          <News />
        </div>
        <WhatIsPman />
        <div id="issue">
          <IssuesInPetOwnership />
        </div>
        <div id="items">
          <PossibleItems />
        </div>
        <div id="features">
          <OurFutures />
        </div>
        <div id="comics">
          <Comics />
        </div>
        <div id="worries">
          <Worries />
        </div>
        <div id="equipment">
          <Equipment />
        </div>
        <div id="area">
          <AreaFloorPlanRent />
        </div>
        <div id="concept">
          <Concept />
        </div>
        <div id="member">
          <Member />
        </div>
        <div id="friends">
          <Friends />
        </div>
        <div id="partner">
          <OurPartner />
        </div>
        <div id="supporter-voice">
          <SupporterVoice />
        </div>
        <Faq />
      </div>
    </Layout>
  );
};
