import React, { useState, useEffect, useRef } from 'react';
/** lib **/
import { Link } from 'react-router-dom';
import { ReactComponent as GreenCircleArrow } from 'assets/images/green-circle-arrow.svg';
import { client } from 'api/microcms';
/** components **/
import { ContentsTitle } from '../molecules';
import { FadeUp } from 'components/organisms';
import InfoImage from 'assets/images/info.png';

import Pwan from 'assets/images/pwan.png';
/** styles,images **/
import s from '../../styles/organisms/news.module.scss';
import { REGISTER_FORM_URL } from '../../const';
import classNames from 'classnames';
import dayjs from 'dayjs';

export const News: React.FC = () => {
  const [news, setNews] = useState([]);

  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const animationEl = [inViewRef1];

  const clickBtn = () => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'click', {
        action: 'サポーター登録ボタンクリック(ファーストビュー)',
      });
    }
    window.open(REGISTER_FORM_URL, '_blank');
  };


  const tagImage = (tag: string): string => {
    console.log(tag)
    switch (tag) {
      case "お知らせ":
        return "friend01.png";
      case "グループ情報":
        return "friend02.png";
      case "物件情報":
        return "friend03.png";
      case "プレスリリース":
        return "friend04.png";
      case "メディア掲載":
        return "friend03.png";
      case "イベント":
        return "friend03.png";
      default:
        return "friend01.png";
    }
  }

  useEffect(() => {
    client
      .get({
        endpoint: "news",
      })
      .then((res) => {
        setNews(res.contents)
      })
      .catch((err) => console.log(err));
  }, []);


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(s.inview);
          } else {
            entry.target.classList.remove(s.inview);
          }
        });
      },
      {
        root: null,
        rootMargin: '-20% 0px -20% 0px',
        threshold: 0,
      },
    );
    animationEl.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      animationEl.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [animationEl]);

  return (
    <div className={s.newsWrapper}>
      <FadeUp delay={0}>
        <div className={s.newsContents}>
          <div className={s.newsContentsInner}>

            <div className={s.notice} ref={inViewRef1}>
              <p>ちょこちょこ更新してるよ！</p>
              <img src={Pwan} alt="ピーワン" />
            </div>
            <ContentsTitle className={s.contentsTitle} title="お知らせ" subTitle={`P-manからのお知らせやペットフレンドリー物件情報`} />
            <div className={s.contentsRow}>

              <ul className={s.newaList}>
                {news.map((newsItem: any) => (
                  <li key={newsItem.id}>
                    {newsItem.link ?
                      // 内部リンクがある場合
                      <Link to={newsItem.link}>
                        <div className={s.text}>
                          <p className={s.date}>
                            {dayjs(newsItem.publishAt).format("YYYY/MM/DD")}
                            <span className={s.tag}>
                              {newsItem.tag[0]}
                            </span>
                          </p>

                          <p>{newsItem.title}</p>
                        </div>
                        <div className={s.icon}><GreenCircleArrow /></div>
                      </Link>
                      :
                      newsItem.external_link ?
                        // 外部リンクがある場合
                        <a href={newsItem.external_link} target="_blank" rel="noreferrer">
                          <div className={s.text}>
                            <p className={s.date}>
                              {dayjs(newsItem.publishAt).format("YYYY/MM/DD")}
                              <span className={s.tag}>
                                {newsItem.tag[0]}
                              </span>
                            </p>

                            <p>{newsItem.title}</p>
                          </div>
                          <div className={s.icon}><GreenCircleArrow /></div>
                        </a> : newsItem.contents ?
                          // ニュース本文がある場合
                          <Link to={`/news?id=${newsItem.id}`}>
                            <div className={s.text}>
                              <p className={s.date}>
                                {dayjs(newsItem.publishAt).format("YYYY/MM/DD")}
                                <span className={s.tag}>
                                  {newsItem.tag[0]}
                                </span>
                              </p>
                              <p>{newsItem.title}</p>
                            </div> <GreenCircleArrow />
                          </Link> :
                          // タイトルのみ
                          <div className={s.text}>
                            <p className={s.date}>
                              {dayjs(newsItem.publishAt).format("YYYY/MM/DD")}
                              <span className={s.tag}>
                                {newsItem.tag[0]}
                              </span>
                            </p>
                            <p>{newsItem.title}</p>
                          </div>}
                  </li>
                ))}
              </ul>
              <div className={s.info}>
                <div className={s.spOnly}>
                  <div className={s.tag}>おすすめ物件INFO</div>
                  <p>猫ちゃんフレンドリー物件「都島テラス」募集スタート！！</p>
                </div>
                <div className={classNames(s.tag, s.pcOnly)}>おすすめ物件INFO</div>
                <a href='https://www.mecsumai.com/tphb-miyakojima-terrace/' target='_blank' rel="noreferrer" >
                  <img src={InfoImage} alt="猫ちゃんフレンドリー物件「都島テラス」募集スタート！！" />
                </a>
                <p className={s.pcOnly}>猫ちゃんフレンドリー物件「都島テラス」募集スタート！！</p>
              </div>
            </div>
          </div>
        </div>
      </FadeUp>
      <FadeUp>
        <div className={s.textWrapper}>
          <div className={s.leadContents}>
            {/* <div className={s.leadText}>サポーター募集中！</div> */}
            <p>
              あなたの ”あったらいいな” が現実になるかも？
              <br />
              サポーター登録をして皆さんの意見やアイディアをぜひお聞かせください。
            </p>
            <p className={s.note}>
              応募にはサポーター登録をしていただく必要がございます。サポーター様にはイベントのご案内やいち早く最新情報をお届けします。
            </p>
            <p className={s.note}>※ 提供有無は物件によって異なります</p>
            <button className={s.linkToForm} onClick={clickBtn}>
              サポーター登録（無料）
              <GreenCircleArrow className={s.arrowIcon} />
            </button>
            <div className={s.baloon}>
              サポーター登録2700人突破！<span>※11月1日時点</span>
            </div>
          </div>
        </div>
      </FadeUp>
      {/* <ParallaxItem className={s.parallaxBg}>
        <img src={BgLogo} alt={SERVICE_NAME} width={381} height={500} />
      </ParallaxItem> */}
    </div>
  );
};
