import React, { useState, useEffect } from 'react';
/** components **/
import { Link } from 'react-router-dom';
import { Layout } from 'components/organisms';
import { useLocation } from 'react-router-dom';
import { client } from 'api/microcms';
import dayjs from "dayjs"
import PwanImage from 'assets/images/p-wan.png';
/** styles **/
import s from '../styles/pages/news.module.scss';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const processContent = (content: string) => {
  return content.replace(/:p-wan/g, `<img src=${PwanImage} alt="p-wan" style="max-width: 50px; height: auto; display: inline-block;margin-bottom: 0;vertical-align: bottom;" />`);
}

export const News: React.FC = () => {
  const [news, setNews] = useState<{ title: string, contents: string, publishedAt: string, tag: string }>();
  const query = useQuery();
  const id = query.get('id');

  const fetch = async () => {
    await client
      .get({
        endpoint: "news",
        contentId: id as string,
      })
      .then((res) => {
        console.log(res)
        setNews(res)
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (id) {
      fetch()
    }
  }, [news, id]);


  return (
    <Layout>
      <div className={s.newsWrapper}>
        <div className={s.contents}>

          <p className={s.title}>{news?.title}</p>
          <p className={s.date}>{dayjs(news?.publishedAt).format("YYYY/MM/DD")} <span>{news?.tag}</span></p>
          <div
            className={s.newsBody}
            dangerouslySetInnerHTML={{
              __html: news?.contents ? processContent(news.contents) : '',
            }}
          />
          <Link className={s.topLink} to="/">
            トップページへ戻る
          </Link>
        </div>

      </div>
    </Layout>
  );
};
